import { set } from 'jsonpointer'
import { refDebounced } from '@vueuse/core'

/**
 * SQLite doesn't have case sensitive search, but postgreSQL which we use in production
 * uses case sensitive search for default.
 * We are turning this off by adding { mode: 'insensitive' } into search object
 */
export default (queryConfig: { filters: string[] }, debounceWhereMilliseconds = 300, minimumSearchTermLength = 1) => {
  const { filters: jsonPointerFieldFiltersForFulltextSearch } = queryConfig
  const fulltextSearchValue = ref<string>('')
  const fulltextSearchValueDebounced = refDebounced(fulltextSearchValue, debounceWhereMilliseconds)

  const where = computed(() => {
    const where_: { [k: string]: any } = {}

    if (fulltextSearchValueDebounced.value.length >= minimumSearchTermLength) {
      where_.OR = jsonPointerFieldFiltersForFulltextSearch.map((fieldFilter) => {
        const filter = {}
        set(filter, fieldFilter, { contains: fulltextSearchValueDebounced.value, mode: 'insensitive' })
        return filter
      }).filter(filter => typeof filter !== 'undefined')

      return where_
    }
  })

  return {
    fulltextSearchValue,
    where,
  }
}
